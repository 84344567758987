import React, { Component } from 'react';
import classNames from 'classnames';

export default class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  render() {
    const { expanded } = this.state;
    const { category, status, entity_id, entity_name, short_description, long_description } = this.props;
    return (
      <div key={`${category}-${entity_id}`} 
        className={classNames('status-item', {
          'out-of-date': status === 'out of date',
          'up-to-date': status === 'up to date'
        })}
      >
        <div
          className='status-item-name'
          onClick={() => this.setState({ expanded: !expanded })}
        >{entity_name}</div>
        {expanded ? (
          <div className='status-expanded'>
            <div><label>ID</label>: {entity_id}</div>
            <div><label>Status</label>: {short_description ? short_description : status}</div>
            {long_description ? (
              <div><label>Description</label>: {long_description}</div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
