import React, { Component } from 'react';
import sortBy from 'lodash.sortby';
import groupBy from 'lodash.groupby';
import queryString from 'query-string';

import Status from './Status';
import { STATUS_URL } from './config';
import mpgLogo from './img/mpg_logo.png';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      onlyShowErrors: false,
      statuses: []
    };
  }

  componentWillMount() {
    const qs = queryString.parse(window.location.search);
    fetch(`${STATUS_URL}?${queryString.stringify({ key: qs.key })}`)
      .then(response => response.json())
      .then(response => {
        if (response.message) {
          this.setState({ error: response.message });
        }
        else {
          this.setState({ statuses: response });
        }
      });
  }

  groupByCategory(statuses) {
    return groupBy(statuses, status => status.category);
  }

  groupByStatus(statuses) {
    return groupBy(statuses, status => status.status);
  }

  render() {
    const { error, onlyShowErrors, statuses } = this.state;
    const grouped = this.groupByCategory(statuses);
    const categories = Object.keys(grouped).sort();

    return (
      <div className='App'>
        <nav className='navbar navbar-default navbar-fixed-top' role='navigation'>
          <div className='container-fluid'>
            <div className='navbar-header'>
              <a className='navbar-brand' href='/'>
                <img id='image-brand' src={mpgLogo} alt='MPG Ranch' />
              </a>
            </div>
            <ul className='nav navbar-nav'>
              <li className='dropdown'>
                <span className='dropdown-toggle' data-toggle='dropdown' role='button' aria-expanded='false'>Data <span className='caret'></span></span>
                <ul className='dropdown-menu' role='menu'>
                  {categories.map(category => (
                    <li key={category}>
                      <a href={`#${category}`}>{category}</a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <form className='navbar-form navbar-left'>
              <div className='form-group'>
                <input
                  type='checkbox'
                  className='form-control'
                  id='only-errors'
                  value={onlyShowErrors} 
                  onChange={e => this.setState({ onlyShowErrors: e.target.checked })}
                />
                <label htmlFor='only-errors'>Only show errors</label>
              </div>
            </form>
          </div>
        </nav>

        <div className='container'>
          <div className='row'>
            <div className='col-sm-8 col-sm-offset-2'>
              <img className='img-center' src={mpgLogo} alt='MPG Ranch' />
            </div>
          </div>
          {error ? (
            <div className='error-message'>
              Error loading statuses, please include the <strong>key</strong> in the URL to view them.
            </div>
          ) : null}
          <div className='row status-list-container'>
            {categories.map(category => (
              <div key={category} className='panel panel-default panel-ranch col-sm-4' style={{float: 'left'}} id={category}>
                <div className='panel-heading'>
                  <div className='h3 text-center category-name'>{category}</div>
                </div>
                <div className='panel-body'>
                  <ul className='status-list'>
                    {sortBy(grouped[category], status => status.entity_name).map(status => {
                      if (status.status === 'up to date' && onlyShowErrors) return null;
                      return (
                        <Status key={status.entity_name} {...status} />
                      );
                    })}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
